<template>
  <b-container>
    <div class="setting">
      <div class="d-flex align-items-center mt-2 mb-2 px-3">
        <h4 class="mr-auto mb-0">Cài đặt tài khoản</h4>
        <b-button variant="success" size="sm" @click="save">Lưu</b-button>
      </div>

      <b-card-group deck>
        <b-card title="Cú pháp" tag="article" class="mb-2 card-border-top">
          <setting-item
            name="da-auto"
            title="Cú pháp đá:"
            :des="desdaAuto"
            :datas="optiondaAuto"
            :current="da_auto_selected"
            @change="setdaAuto"
          >
          </setting-item>

          <setting-item
            name="duoi4c"
            title="Cú pháp đuôi 3c,4c:"
            :des="desDuoi4c"
            :datas="optionDuoi4c"
            :current="duoi4c_selected"
            @change="setDuoi4c"
          >
          </setting-item>

          <setting-item
            name="sodatrung"
            title="Số đá trùng:"
            :des="desSoDaTrung"
            :datas="optionSoDaTrung"
            :current="sodatrung_selected"
            @change="setSoDaTrung"
          >
          </setting-item>

          <setting-item
            name="auto-db-t6"
            title="'bd' là Bình Dương:"
            :des="desAutoDBT6"
            :datas="optionAutoDBT6"
            :current="auto_db_t6_selected"
            @change="setAutoDBT6"
          >
          </setting-item>

          <setting-item
            name="keo-all"
            title="Kéo đủ số:"
            :des="desKeoAll"
            :datas="optionKeoAll"
            :current="keo_all_selected"
            @change="setKeoAll"
          >
          </setting-item>
        </b-card>

        <b-card title="Báo cáo" tag="article" class="mb-2">
          <setting-item
            name="thuc-thu"
            title="Báo sổ | Thực thu:"
            :des="desThuThu"
            :datas="optionThuThu"
            :current="thuc_thu_selected"
            @change="setThuThu"
          >
          </setting-item>

          <setting-item
            name="show-type"
            title="Hiển thị:"
            :des="desShowType"
            :datas="optionShowType"
            :current="report_show_type_selected"
            @change="setShowType"
          >
          </setting-item>

          <setting-item
            name="show-turn-over-detail"
            title="Hiển thị:"
            :des="desShowTurnOverDetail"
            :datas="optionShowTurnOverDetail"
            :current="report_is_show_turn_over_detail_selected"
            @change="setShowTurnOverDetail"
          >
          </setting-item>

          <setting-item
            name="delete-sms"
            title="Tự xóa tin:"
            :des="desDelete"
            :datas="optionDelete"
            :current="delete_sms_selected"
            @change="setDeteleSms"
          >
          </setting-item>
        </b-card>
      </b-card-group>

      <b-card-group deck>
        <b-card title="Cảnh báo" tag="article" class="mb-2">
          <b-input-group
            class="mb-2"
            size="sm"
            :prepend="item.name"
            v-for="(item, name, indexItem) in warning"
            :key="indexItem"
          >
            <b-form-input
              class="limit-day"
              :origin="item.value"
              :value="item.value"
              :prop="name"
            ></b-form-input>
          </b-input-group>
        </b-card>
      </b-card-group>
    </div>
  </b-container>
</template>
<script>
import SettingItem from "@/components/SettingItem";

export default {
  name: "Setting",
  components: {
    SettingItem
  },
  data() {
    return {
      da_auto_selected: 0,
      optiondaAuto: [
        { name: "Có auto", value: 1 },
        { name: "Không auto", value: 0 }
      ],
      desdaAuto: [
        "- Nếu có hệ thống sẽ tự động tính dx khi có 2 đài. VD:2d11.22da10 => 2d11.22dx10",
        "- VD: la hg 22.33da20 => la.hg22.33dx20"
      ],

      duoi4c_selected: 0,
      optionDuoi4c: [
        { name: "Có auto", value: 1 },
        { name: "Không auto", value: 0 }
      ],
      desDuoi4c: [
        "- Tự động: nhập 1234duoi10n tự chuyển thành 34duoi10n.",
        "nhập 123duoi 10n tự chuyển thành 23 đuôi 10n",
        "- Không tự động: nhập 1234duoi10n tính 1234 đuôi 10n.",
        "nhập 123duoi 10n báo lỗi"
      ],

      sodatrung_selected: 0,
      optionSoDaTrung: [
        { name: "Có nhận đá trùng", value: 1 },
        { name: "Không nhận đá trùng", value: 0 }
      ],
      desSoDaTrung: [
        "- Có nhận: nhập hcm 22 22 33 da 10 tính đá 22,22 22,23 22,23.",
        "- Không nhận: nhập hcm 22 22 33 da 10 => Báo lỗ"
      ],

      auto_db_t6_selected: 1,
      optionAutoDBT6: [
        { name: "Có", value: 1 },
        { name: "Không", value: 0 }
      ],
      desAutoDBT6: [
        "- Có: Thứ 6 hệ thống sẽ tự hiểu 'bd' là đài Bình Dương",
        "- Không: Thứ 6 hệ thống sẽ hiểu 'bd' là bao đảo"
      ],

      keo_all_selected: 1,
      optionKeoAll: [
        { name: "Có", value: 1 },
        { name: "Không", value: 0 }
      ],
      desKeoAll: [
        "- Có: tin 10keo30 => 10,11,12,13,14,15,...,28,29,30",
        "- Không: tin 10keo30 => 10,20,30"
      ],

      thuc_thu_selected: 0,
      optionThuThu: [
        { name: "Có hiện thực thu", value: 1 },
        { name: "Không hiện thực thu", value: 0 }
      ],
      desThuThu: [
        "- Nếu có báo sổ sẽ hiển thị thêm thực thu ở các miền. VD:26/02, T4 Khách A",
        "- MN: 2c: 5,400 3c: 2,422 (ThucThu: 5,944.72)"
      ],

      report_show_type_selected: 0,
      optionShowType: [
        { name: "Danh sách", value: 1 },
        { name: "Bảng", value: 0 }
      ],
      desShowType: [
        "- Danh sách: Giao diện mobile ",
        "- Bảng: Giao diện volam"
      ],

      report_is_show_turn_over_detail_selected: 0,
      optionShowTurnOverDetail: [
        { name: "Hiện 2D,3D,4D,DA,DX", value: 1 },
        { name: "Ẩn 2D,3D,4D,DA,DX", value: 0 }
      ],
      desShowTurnOverDetail: ["- Chỉ có tác dụng ở dạng danh sách"],

      delete_sms_selected: 14,
      optionDelete: [
        { name: "Giữ tin tuần trước", value: 7 },
        { name: "Giữ tin 2 tuần trước", value: 14 },
        { name: "Giữ tin 3 tuần trước", value: 21 },
        { name: "Giữ tin 4 tuần trước", value: 28 }
      ],
      desDelete: [
        "Tin sẽ tự động xóa và 23h59 thứ 2",
        "- Giữ tin 1 tuần trước: Hệ thống sẽ giữ lại tin của 1 tuần trước đó, còn lại sẽ tự động xoá vào 23h59 thứ 2",
        "- Giữ tin 2 tuần trước: Hệ thống sẽ giữ lại tin của 2 tuần trước đó, còn lại sẽ tự động xoá vào 23h59 thứ 2.",
        "- Giữ tin 3 tuần trước: Hệ thống sẽ giữ lại tin của 3 tuần trước đó, còn lại sẽ tự động xoá vào 23h59 thứ 2.",
        "- Giữ tin 4 tuần trước: Hệ thống sẽ giữ lại tin của 4 tuần trước đó, còn lại sẽ tự động xoá vào 23h59 thứ 2.c"
      ],

      warning: {
        3: { name: "2D Bao lô:", value: 0 },
        1: { name: "2D Đầu đuôi:", value: 0 },
        8: { name: "3D Bao lô:", value: 0 },
        6: { name: "3D Xiu chủ:", value: 0 },
        11: { name: "4D:", value: 0 },
        14: { name: "Đá thẳng:", value: 0 },
        17: { name: "Đá xiên:", value: 0 }
      }
    };
  },
  async created() {
    await this.fetchUserSetting();
    await this.fetchUserLimit();
  },
  methods: {
    async fetchUserSetting() {
      let res = await this.$http.get("/user/setting");
      if (res.data.code == 0) {
        this.da_auto_selected = res.data.data.auto_dx;
        this.duoi4c_selected = res.data.data.auto_4c;
        this.sodatrung_selected = res.data.data.so_da_trung;
        this.thuc_thu_selected = res.data.data.thucthu;
        this.auto_db_t6_selected = res.data.data.auto_db_t6;
        this.keo_all_selected = res.data.data.keo_all;
        this.report_show_type_selected = res.data.data.report_show_type;
        this.report_is_show_turn_over_detail_selected =
          res.data.data.report_is_show_turn_over_detail;
        this.delete_sms_selected = res.data.data.delete_sms;
      }
    },
    async fetchUserLimit() {
      let res = await this.$http.get("/user/setting/limit");
      if (res.data.code == 0) {
        let limit = { ...this.warning };

        for (let prop in res.data.data) {
          limit[prop].value = res.data.data[prop].limit_day;
        }

        this.warning = limit;
      }
    },
    async save() {
      await this.saveSetting();
      await this.saveWarning();
      await this.$store.dispatch("getUserSetting");
    },
    async saveSetting() {
      let res = await this.$http.post("/user/setting", {
        auto_dx: this.da_auto_selected,
        auto_4c: this.duoi4c_selected,
        so_da_trung: this.sodatrung_selected,
        thucthu: this.thuc_thu_selected,
        auto_db_t6: this.auto_db_t6_selected,
        keo_all: this.keo_all_selected,
        report_show_type: this.report_show_type_selected,
        report_is_show_turn_over_detail: this
          .report_is_show_turn_over_detail_selected,
        delete_sms: this.delete_sms_selected
      });

      if (res.data.code == 0) {
        this.makeToast("Thành công", "Lưu cài đặt thành công", "success", true);
      } else {
        this.makeToast("Lỗi", res.data.msg, "danger", true);
      }
    },
    async saveWarning() {
      let limit = this.getChangedConfig();
      let res = await this.$http.post("/user/setting/limit", {
        config: limit
      });

      if (res.data.code == 0) {
        this.makeToast(
          "Thành công",
          "Lưu giới hạn cảnh báo thành công",
          "success",
          true
        );
      } else {
        this.makeToast("Lỗi", res.data.msg, "danger", true);
      }
    },
    getChangedConfig() {
      let filter = Array.prototype.filter;
      let changed = filter
        .call(document.querySelectorAll(".limit-day"), function(e) {
          let origin = e.getAttribute("origin");
          let current = e.value;
          return origin != current;
        })
        .map(e => {
          return {
            prop_id: parseInt(e.getAttribute("prop")),
            limit_day: parseInt(e.value)
          };
        });

      return changed;
    },
    setdaAuto(value) {
      this.da_auto_selected = value;
    },
    setDuoi4c(value) {
      this.duoi4c_selected = value;
    },
    setSoDaTrung(value) {
      this.sodatrung_selected = value;
    },
    setAutoDBT6(value) {
      this.auto_db_t6_selected = value;
    },
    setKeoAll(value) {
      this.keo_all_selected = value;
    },
    setThuThu(value) {
      this.thuc_thu_selected = value;
    },
    setShowType(value) {
      this.report_show_type_selected = value;
    },
    setShowTurnOverDetail(value) {
      this.report_is_show_turn_over_detail_selected = value;
    },
    setDeteleSms(value) {
      this.delete_sms_selected = value;
    },
    makeToast(title, content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 3000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5"
      });
    }
  }
};
</script>
<style>
.setting .card-deck {
  text-align: left;
}
.setting .input-group-text {
  width: 140px;
}
</style>
