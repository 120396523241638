<template>
  <b-input-group class="mb-2" size="sm" :prepend="title">
    <b-form-select v-model="data_selected" @change="onChange">
      <b-form-select-option
        v-for="(d, index) in datas"
        :key="index"
        :value="d.value"
        >{{ d.name }}</b-form-select-option
      >
    </b-form-select>
    <b-input-group-append>
      <b-button variant="outline-secondary" :id="'popover-' + name">
        <b-icon icon="exclamation-circle"></b-icon>
      </b-button>
    </b-input-group-append>
    <b-popover :target="'popover-' + name" variant="info" triggers="focus">
      <template #title>Chú thích</template>
      <span v-for="(d, index) in des" :key="index"
        ><small>{{ d }}</small
        ><br
      /></span>
    </b-popover>
  </b-input-group>
</template>
<script>
export default {
  props: ["name", "title", "des", "datas", "current"],
  data() {
    return {
      data_selected: 0
    };
  },
  created() {
    this.data_selected = this.current;
  },
  watch: {
    current: function(value) {
      this.data_selected = value;
    }
  },
  methods: {
    onChange(value) {
      this.$emit("change", value);
    }
  }
};
</script>
